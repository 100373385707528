import React, { useEffect, useRef, useState } from "react";
import HomeBanner from "../../components/HomeBanner";
import Category from "../../components/category";
import PopularBrands from "../../components/popularBrands";
import ProductSlider from "../../components/sliders/productSlider";
import Featureds from "../../components/Featureds";
import Product from "../../components/cards/Product";
import axios from "axios";
import Footer from "../../components/footer";
import { Base_url } from "../../utils/Base_url";
import Navbar from "../../components/Header/Navbar";
import BottomHeader from "../../components/Header/BottomHeader";
import TopDeals from "../../components/Deals/TopDeals";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Subscribe from "../../components/Subscribe";

const Home = () => {
  const [allProduct, setAllProduct] = useState([]);
  const [newsProduct, setNewsProduct] = useState([]);
  const User = localStorage.getItem("user_ID") || undefined;

  const { data, isAuthenticated } = useSelector((state) => state.auth);

  console.log(isAuthenticated, "userdata");

  const [banner, setBanner] = useState([]);
  const [topSearch, setTopSearch] = useState([]);

  useEffect(() => {
    axios
      .get(`${Base_url}/product/get`)
      .then((res) => {
        console.log(res);

        setAllProduct(res.data.data, "all products");
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/product/getNewArrival`)
      .then((res) => {
        console.log(res);

        setNewsProduct(res.data.data, "all products");
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/banner/get?location=home`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        setBanner(res?.data?.data);
      })
      .catch((error) => {});

    axios
      .get(`${Base_url}/search/get?userId=${User}`)
      .then((res) => {
        console.log(res?.data?.data, "==============>>>>>>>>>sub");

        const uniqueSearches = res?.data?.data?.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (search) =>
                search.productId === item.productId &&
                search.userId === item.userId
            )
        );

        setTopSearch(uniqueSearches);
      })
      .catch((error) => {});
  }, []);

  const sliderRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const checkScrollPosition = () => {
      if (sliderRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
      }
    };

    // Add event listener to handle scroll
    const slider = sliderRef.current;
    slider.addEventListener("scroll", checkScrollPosition);

    // Initial check
    checkScrollPosition();

    return () => {
      slider.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const scrollLeft = () => {
    sliderRef.current.scrollBy({
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    sliderRef.current.scrollBy({
      left: 200,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <Navbar />
      <BottomHeader />
      <HomeBanner />
      <div className="grid bg-gray-200  sm:py-5 py-3  grid-cols-1">
        <div className=" relative  container mx-auto px-0">
          {/* Left Arrow */}
          {/* {showLeftArrow && (
            <button
              onClick={scrollLeft}
              className="absolute  flex justify-center items-center w-10 h-10  left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full z-10 "
            >
              <LiaAngleLeftSolid size={20} color="#000" />
            </button>
          )} */}

          <div
            ref={sliderRef}
            className="flex flex-wrap   gap-4 overflow-x-auto  justify-between  px-8"
          >
            <Link
              to={"/delivery-pickup/standard-delivery"}
              className="min-w-[300px] flex justify-center items-center"
            >
              <div>
                <h6 className="mb-0 font-medium flex items-center gap-2">
                  {" "}
                  <svg
                    className=""
                    width="40"
                    height="40"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    class="Icon Icon_Icon__Lc37_ PropositionMessages_Icon__heQPk"
                    data-di-rand="1732253144071"
                  >
                    <path
                      d="M25.263 10.273H6.177V27.32h19.086V10.273Z"
                      fill="#AEEAFF"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="m23.468 16.04-.816 2.366L22 16.04h-1.06l-.735 2.365-.815-2.365h-1.387l1.55 4.404h1.142l.734-2.365.734 2.365h1.142l1.55-4.404h-1.387Zm-14.029 0h-2.12v4.323h2.12c1.142 0 1.387-.734 1.387-1.223 0-.49-.245-.897-.735-.979.408-.163.653-.49.653-.979.082-.407-.082-1.141-1.305-1.141Zm.163 1.306c0 .244-.163.407-.49.407h-.489v-.897h.408c.49.082.571.245.571.49Zm-.979 1.223v.897h.408c.408 0 .49-.245.49-.408 0-.244-.082-.407-.49-.407h-.408v-.082Zm2.774-2.528H12.7v4.404h-1.305v-4.404Zm4.24 1.957h2.04v.326c0 .163 0 .408-.082.571-.163 1.06-1.142 1.713-2.12 1.631-.653.082-1.224-.163-1.632-.652-.407-.408-.652-1.06-.652-1.631 0-.653.245-1.224.652-1.631.408-.408 1.06-.653 1.713-.653 1.06-.082 1.958.57 2.12 1.631H16.21c-.082-.326-.408-.57-.734-.49-.653 0-.98.572-.98 1.224 0 .327.083.571.246.816.163.245.489.326.815.326.408.082.734-.245.816-.652h-.734v-.816Z"
                      fill="#01005E"
                    ></path>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.164 8.537h2.854c1.06 0 1.876.816 1.876 1.876v16.802c0 1.06-.815 1.876-1.876 1.876H6.422c-1.06-.082-1.876-.897-1.876-1.957V10.33c0-1.06.816-1.876 1.876-1.876h2.855c.407-3.099 3.18-5.546 6.443-5.546 3.263 0 6.036 2.447 6.444 5.628Zm-11.012 0a4.646 4.646 0 0 1 4.568-3.752 4.646 4.646 0 0 1 4.568 3.752h-9.136Zm-4.975 18.76V10.25h19.086v17.047H6.177Z"
                      fill="#01005E"
                    ></path>
                  </svg>{" "}
                  Standard Delivery
                </h6>
                <p className=" text-sm text-gray-500">
                  Our standard delivery charges are 12$
                </p>
              </div>
            </Link>
            <Link
              to={`/delivery-pickup/standard-delivery`}
              className="min-w-[300px] flex justify-center items-center"
            >
              <div>
                <h6 className="mb-0 font-medium flex items-center gap-2">
                  {" "}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    class="Icon Icon_Icon__Lc37_ PropositionMessages_Icon__heQPk"
                    data-di-rand="1732253144072"
                  >
                    <path
                      d="m28.11 16.966-3.487-3.69a.61.61 0 0 0-.443-.19H21.01a.612.612 0 0 0-.613.613v5.665a3.849 3.849 0 0 1 5.204 2.246h2.078a.61.61 0 0 0 .609-.612v-3.61a.62.62 0 0 0-.18-.422Z"
                      fill="#fff"
                    ></path>
                    <path
                      d="M22.096 13.4v3.165h5.277l-2.531-3.186-2.746.021Z"
                      fill="#FFB000"
                    ></path>
                    <path
                      d="M9.618 20.755a1.975 1.975 0 1 0 .014 3.95 1.975 1.975 0 0 0-.014-3.95ZM21.927 20.702a1.979 1.979 0 1 0 .007 3.957 1.979 1.979 0 0 0-.007-3.957Z"
                      fill="#01005E"
                    ></path>
                    <path
                      d="M17.987 8.964H4.68a.627.627 0 0 0-.626.626v11.267a.627.627 0 0 0 .626.627H6.28a3.849 3.849 0 0 1 7.161 0h4.577a.627.627 0 0 0 .585-.638V9.58a.627.627 0 0 0-.627-.627"
                      fill="#AEEAFF"
                    ></path>
                    <path
                      d="M17.831 8.636H4.362a.624.624 0 0 0-.449.192.666.666 0 0 0-.185.464v11.78c0 .174.066.34.185.463.12.123.28.192.449.192H5.98a4.003 4.003 0 0 1 1.432-1.852 3.81 3.81 0 0 1 2.193-.697 3.81 3.81 0 0 1 2.193.697 4.003 4.003 0 0 1 1.432 1.852h4.633a.627.627 0 0 0 .425-.21.668.668 0 0 0 .167-.456V9.28a.666.666 0 0 0-.186-.453.625.625 0 0 0-.438-.192ZM28.11 16.966l-3.487-3.69a.61.61 0 0 0-.443-.19H21.01a.612.612 0 0 0-.613.613v5.665a3.849 3.849 0 0 1 5.204 2.246h2.078a.61.61 0 0 0 .609-.612v-3.61a.62.62 0 0 0-.18-.422Z"
                      stroke="#01005E"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                    ></path>
                    <path
                      d="M11.797 11.848H6.182"
                      stroke="#01005E"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>{" "}
                  FREE Delivery over $65{" "}
                </h6>
                <p className=" text-sm text-gray-500">
                  For Standard Metro in 2-5 days.
                </p>
              </div>
            </Link>
            <Link
              to={
                "https://www.everyday.com.au/extra.html?_branch_match_id=1376206915622969268&utm_source=bigw&utm_campaign=SUA-6249&utm_medium=website&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXL0otTyxKKdZLLCjQy8nMy9Y3d0zzyvM2LQn1TLKvK0pNSy0qysxLj08qyi8vTi2ydc4oys9NBQCBSbM%2FPgAAAA%3D%3D"
              }
              className="min-w-[300px] flex justify-center items-center"
            >
              <div>
                <h6 className="mb-0 font-medium flex items-center gap-2">
                  {" "}
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    class="Icon Icon_Icon__Lc37_ PropositionMessages_Icon__heQPk"
                    data-di-res-id="ad955b4a-f6ed7f85"
                    data-di-rand="1732267194299"
                  >
                    <path
                      d="M2.127 18.712A.127.127 0 0 1 2 18.585V9.818a3.823 3.823 0 0 1 3.812-3.812h16.061c.07 0 .127.058.127.127V14.9a3.823 3.823 0 0 1-3.811 3.812H2.127Z"
                      fill="#FF6400"
                    ></path>
                    <path
                      d="M14.417 8.926h-1.521c-.857 0-1.553.692-1.56 1.548V9.226a.29.29 0 0 0-.285-.3H9.782a.289.289 0 0 0-.285.3v6.285c0 .156.128.285.285.285H11.05a.286.286 0 0 0 .285-.285v-3.892c0-.01 0-.019.002-.028a2.02 2.02 0 0 1 .543-1.119c.408-.42.976-.65 1.601-.65.043 0 .08.033.082.076a.082.082 0 0 1-.07.087c-.008.002-.865.136-1.355.824-.165.23-.27.496-.317.796h1.126c.862 0 1.56-.699 1.56-1.56V9.018a.092.092 0 0 0-.091-.092Z"
                      fill="#fff"
                    ></path>
                  </svg>
                  Everday Extra Annual Plan 1/2 price
                </h6>
                <p className=" text-sm text-gray-500">
                  Now only $35 for the first year+
                </p>
              </div>
            </Link>
          </div>

          {/* Right Arrow */}

          {/* {showRightArrow && (
            <button
              onClick={scrollRight}
              className="absolute right-0 flex justify-center items-center top-1/2 transform -translate-y-1/2 bg-white text-white  w-10 h-10 rounded-full z-10 "
            >
              <LiaAngleRightSolid size={20} color="#000" />
            </button>
          )} */}
        </div>
      </div>

      <TopDeals />

      <div className=" container  px-4  mx-auto pb-10">
        <Link to={`${banner?.[0]?.link}`} className="   mx-auto">
          <img
            src={banner?.[0]?.image}
            className=" mx-auto sm:h-auto h-32 object-cover w-full"
            alt=""
          />
        </Link>
        <Category />
        <Link to={`${banner?.[1]?.link}`}>
          {banner?.[1]?.image ? (
            <img
              src={banner?.[1]?.image}
              className="sm:h-auto mx-auto  h-32 object-center w-full"
              alt=""
            />
          ) : null}
        </Link>
      </div>

      {newsProduct?.length > 0 && isAuthenticated && (
        <>
          <div className=" flex container px-4 mx-auto items-center  justify-between">
            <h4 className=" font-medium text-2xl text-black">New Arrivals</h4>
            <Link
              to={`/new-all-arrivals?product=undefined&limit=10&page=1&sortBy=&brandIds=&categoryIds=&subCategoryIds=&subSubCategoryIds=&colorName=&minPrice=&maxPrice=&priceComparison=&deal=["New"]`}
              className=" cursor-pointer  text-sm text-blue-600"
            >
              Shop all New Arrivals
            </Link>
          </div>

          <div className=" py-12">
            <ProductSlider
              top={20}
              items={newsProduct?.map((item, index) => {
                if (item?.discount > 10) {
                }
                return (
                  <>
                    <div className="">
                      <Product type={"slider"} item={item} />
                    </div>
                  </>
                );
              })}
            />
          </div>
        </>
      )}

      {/* </div> */}

      {/* {isAuthenticated?

<div className=" container mx-auto">
<div className=" flex  items-center justify-between">
  <h4 className=" font-medium text-2xl text-black">Top searches</h4>
</div>
<div className="   pt-4 gap-6  items-center">
  {topSearch?.length > 0 ? (
    <ProductSlider
      items={topSearch.map((item, index) => {
        return (
          <>
            <CategoryCard
              key={index}
              url={`/categories/${item?.id}`}
              image={item?.image}
              name={item?.name}
            />
          </>
        );
      })}
    />
  ) : (
    <p>Loading products...</p>
  )}
</div>
</div>:null
        
      } */}

      {isAuthenticated ? (
        topSearch?.length > 0 ? (
          <div className="  container pt-6  mx-auto px-3">
            <div className=" pb-10  container mx-auto">
              <h4 className="h4">Recently Viewed</h4>
              <div className=" pt-3">
                <ProductSlider
                  top={20}
                  items={topSearch?.map((item, index) => {
                    return (
                      <>
                        <div>
                          <Product type={"slider"} item={item?.product} />
                        </div>
                      </>
                    );
                  })}
                />
              </div>
            </div>
          </div>
        ) : null
      ) : null}

      <Featureds />
      <PopularBrands />

      <Subscribe />
      {/* <ShopBanner /> */}
      <Footer />
    </div>
  );
};

export default Home;
